import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './styles.module.css';

const JSON_LD_META = [
  {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': '#website',
    url: 'https://www.inventia.com',
    name: 'Inventia Conference'
  },
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.inventia.com',
    '@id': '#organization',
    name: 'Inventia Conference'
  }
];

/**
 * Page template
 * The core page layout that sets up a view
 * Automatically wrapped around all other tempaltes and views by gatsby-plugin-layout
 */
export default class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
			<>
	      <div styleName="page">
	        <Helmet>
	          <html lang="en" />
	          <meta name="author" content="Inventia" />
	          <meta name="designer" content="Erudito" />
	          <meta name="rating" content="general" />
	          <script type="application/ld+json">
	            {JSON.stringify(JSON_LD_META)}
	          </script>
	        </Helmet>
	        <main styleName="content">{children}</main>
	      </div>
			</>
    );
  }
}
